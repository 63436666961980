@keyframes translateinfinite {
  100% {
    transform: translateX(calc(-180px * 10));
  }
}
.clients{
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
}
.highwaySlider {
    width: 100%;
}
.highwaySlider .highwayBarrier {
    overflow: hidden;
    position: relative;
    background: #fff;
    padding: 10px;
}

.highwaySlider .highwayBarrier::before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.highwaySlider .highwayBarrier::after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.highwaySlider .highwayBarrier .highwayLane {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    width: calc(180px * 10);
    margin-left: 0;
    -webkit-animation: translateinfinite 10s linear infinite;
          animation: translateinfinite 10s linear infinite;
}
.highwaySlider .highwayBarrier .highwayLane .highwayCar {
    background: #fff;
    min-width:180px;   
    text-align: center; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.highwaySlider .highwayLane .highwayCar .clientImage img{
    max-width: 80%;
    height: auto;
}